<template>
  <div class="draft-feature">
    <j-form model="drafts.draft" hide-errors>

        <!-- Kicker -->
        <j-control name="kicker" />

        <!-- Headline -->
        <j-control name="title" />

        <!-- Subtitle -->
        <j-control name="subtitle" />
    </j-form>

    <!-- Article Body -->
    <draft-article-body :draft="draft" />
  </div>
</template>

<script>

import DraftArticleBody from './DraftArticleBody'

export default {
  name: 'DraftFeature',
  components: {
    DraftArticleBody
  },
  props: ['draft']
}

</script>
