<template>
  <j-form model="drafts.draft" hide-errors class="draft-article-body">

      <!-- Feature Byline -->
      <j-control
        v-if="draft.format == 'event-reviews' || !draft.format.endsWith('reviews')"
        name="byline"
      />

      <!-- Description -->
      <j-control
        v-if="draft.format != 'music-reviews'"
        name="description"
      />

      <!-- Body -->
      <j-control name="body" />

      <!-- Review Byline -->
      <j-control
        v-if="draft.format.endsWith('reviews') && draft.format != 'event-reviews'"
        name="byline"
      />

      <!-- Related Sites -->
      <j-control name="url" />

      <!-- Tags -->
      <j-control name="tags" />

      <!-- Contact -->
      <j-control name="contact" />
  </j-form>
</template>

<script>

export default {
  name: 'DraftArticleBody',
  props: ['draft']
}

</script>
